import axios from "axios";
import getEnv from "./env";
import { isBrowser } from "./isBrowser";

const getAxiosConfig = () => {
  const commonHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const headers = isBrowser()
    ? commonHeaders
    : {
        ...commonHeaders,
        "User-Agent": "VendorPortalFE",
        "x-capstg-key": getEnv().REACT_APP_VP_API_KEY,
      };

  return {
    ...(!isBrowser() && {
      baseURL: getEnv().REACT_APP_VP_BASE_URL,
      withCredentials: getEnv().NODE_ENV === "production",
    }),
    headers,
  };
};

const authAxios = axios.create(getAxiosConfig());

export const setAxiosFEConfig = ({ flags, employeeId }) => {
  authAxios.defaults.baseURL = getEnv().REACT_APP_VP_BASE_URL;
  authAxios.defaults.withCredentials = getEnv().NODE_ENV === "production";
  authAxios.defaults.headers["x-capstg-key"] = getEnv().REACT_APP_VP_API_KEY;
  authAxios.interceptors.request.use((config) => {
    if (
      config.method.toUpperCase() !== "GET" &&
      getEnv().REACT_APP_ENV === "production"
    ) {
      if (!!employeeId && !flags?.includes("employee_write_access")) {
        throw new Error({
          status: 403,
          message: "Forbidden: You are not authorized to perform this action.",
        });
      }
    }

    return config;
  });
};

export default authAxios;
